import React from 'react'

import Comments from './comments/Comments'


function Recenzii() {
  return (




       <div>
      
        <Comments/>
       </div>

   
  );

}

export default Recenzii
