import React from 'react'
import './Ingrasaminte.css'


export default function MotorinaEnGross() {
  document.documentElement.style.setProperty('--color', `white`);

  return (
    <div>
          <img src='images/engross_pelicula.jpg' className='bg'></img>
      <br/>
     <br/>
     <br/>
    
      <p className="text-center">Interglobal MB OIL SRL livrează carburanți în conformitate cu cele mai înalte standarde de calitate, din gama Euro 5. </p>
      <p className="text-center">
Dacă afacerea ta este din domeniul agriculturii, transporturilor, construcțiilor sau orice alt domeniu,venim în întâmpinarea dumneavoastră cu propuneri și soluții adaptate pentru a reduce costurile cu carburantul. </p>
<p className="text-center">
Dețineți capacități de depozitare pentru carburanți  în incintă sau doriți să intrați în posesia unui rezervor pentru stocarea acestora?<br/>Interglobal MB OIL SRL vă oferă soluțiile optime! <br/>
Știm cât de important este pentru client să poată fi aprovizionat cât mai rapid cu carburanți . Interglobal MB OIL SRL vine în sprijinul dumneavoastră prin livrarea în cel mai scurt timp a cantităților dorite, indiferent de cantitate.
Dacă afacerea ta este în domeniul agriculturii, știi cel mai bine că succesul său depinde de o mulțime de factori – mulți dintre ei incontrolabili. Prin urmare, nu îți poți permite ca stocurile de carburanți să devină și ele o problemă. 
Indiferent de cantitatea de carburant de care aveți nevoie, Interglobal MB OIL SRL livrează tuturor producătorilor agricoli, mici sau mari,carburanți de calitate  cu următoarele mențiuni:
<br></br>•	Livrarea carburantului se face în maxim 24 de ore
<br></br>•	Transportul se realizează  cu  cisterne proprii sau închiriate , cu toate avizele de transport necesare privind mărfurile periculoase.</p>
      
    </div>
  )
}

