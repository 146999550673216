import React, { useState } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fireDB from '../firebase'
import emailjs from '@emailjs/browser';

import './Contact.scss'

function Contact() {


  const sendEmail = (e) => {


    e.preventDefault();
    emailjs.sendForm('service_9u8awjg', 'template_69d9e1f', e.target, 'uNVBaZJr3Z-OLAWnI')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message, phone } = state;

  const handleSubmit = (e) => {

    e.preventDefault();
    if (!name || !email || !subject || !message || !phone) {
      toast.error("Vă rugăm introduceți valori în fiecare câmp.");
    } else {
      setState({ name: "", email: "", subject: "", message: "", phone: "" });
      toast.success("Mesajul a fost trimis cu succes!");
      sendEmail(e);
    }

  }
  const handleInputChange = (e) => {

    let { name, value } = e.target;
    setState({ ...state, [name]: value });

  }
  return (

    <section className='contact-section'>

      <div className='container'>

        <ToastContainer position='top-center' />

        <div className='row-justify-content-center'>

          <div className='col-md-10'>

            <div className='wrapper'>

              <div className='row no-gutters'>

                <div className='col-md-6'>

                  <div className='contact-wrap w-100 p-lg-5 p-4'>

                    <h3 className='mb-4'>Trimite-ne un mesaj! Pentru comandă de motorină(minim 500L): <br/> 
                    Persoana fizică: CNP <br/>
                    Persoana juridică: numele societății, CUI, J

                    </h3>

                    <form id="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}>

                      <div className='row'>

                        <div className='col-md-12'>

                          <div className='form-group'>
                            <input
                              type="text"
                              className='form-control'
                              name='name'
                              placeholder='Nume'
                              onChange={handleInputChange}
                              value={name}

                            />
                          </div>

                        </div>

                        <div className='col-md-12'>

                          <div className='form-group'>
                            <input
                              type="email"
                              className='form-control'
                              name='email'
                              placeholder='Adresa email'
                              onChange={handleInputChange}
                              value={email}

                            />
                          </div>

                        </div>
                        
                        <div className='col-md-12'>

                          <div className='form-group'>
                            <input
                              type="text"
                              className='form-control'
                              name='phone'
                              placeholder='Telefon'
                              onChange={handleInputChange}
                              value={phone}

                            />
                          </div>

                        </div>

                        <div className='col-md-12'>

                          <div className='form-group'>
                            <input
                              type="text"
                              className='form-control'
                              name='subject'
                              placeholder='Subiect'
                              onChange={handleInputChange}
                              value={subject}

                            />
                          </div>

                        </div>

                        <div className='col-md-12'>

                          <div className='form-group'>
                            <textarea
                              type="text"
                              className='form-control'
                              name='message'
                              placeholder=''
                              cols='30'
                              rows='6'
                              onChange={handleInputChange}
                              value={message}

                            ></textarea>

                          </div>

                        </div>


                        <div className='col-md-12'>

                          <div className='form-group'>

                            <input type="submit" value="Send message" className='btn btn-primary' />
                          </div>
                        </div>

                      </div>


                    </form>


                  </div>


                </div>

                <div className='col-md-6 d-flex align-items-stretch'>

                  <div className='info-wrap w-100 p-lg-5 p-4 img'>

                    <h3>Pentru mai multe detalii, ne poți contacta direct! </h3>
               

                    <div className="dbox w-100 d-flex align-items-center">

                      <div className="icon d-flex align-items-center justify-content-center">

                        <span className="fa fa-map-marker"></span>

                      </div>

                      <div className='text pl-3'>

                        <p>

                          <span>Adresa:</span>Calea Smârdan 2, 800701
                        </p>

                      </div>

                    </div>

                    <div className="dbox w-100 d-flex align-items-center">

                      <div className="icon d-flex align-items-center justify-content-center">

                        <span className="fa fa-phone"></span>

                      </div>

                      <div className='text pl-3'>

                        <p>

                          <span>Telefon: 0756298181 / 0743832440</span>
                        
                        </p>

                      </div>

                    </div>

                    <div className="dbox w-100 d-flex align-items-center">

                      <div className="icon d-flex align-items-center justify-content-center">

                        <span className="fa fa-paper-plane"></span>

                      </div>

                      <div className='text pl-3'>

                        <p>

                          <span>Email: office@interglobal.ro</span>
                        
                        </p>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>


          </div>

        </div>

      </div>

    </section>
  );

}

export default Contact
