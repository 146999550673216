import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app"
import { } from 'firebase/compat/database';
import {getFirestore}from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyBV3eSz7dJR43W_apmQpjaFdXJ--EfYYRA",
  authDomain: "contact-6cfab.firebaseapp.com",
  databaseURL: "https://contact-6cfab-default-rtdb.firebaseio.com",
  projectId: "contact-6cfab",
  storageBucket: "contact-6cfab.appspot.com",
  messagingSenderId: "615634331777",
  appId: "1:615634331777:web:cdb5e35b16a14bd8a48580"
};


require("firebase/database");
const fireDb = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(fireDb);

//export default fireDb.database().ref();