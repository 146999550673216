import React from 'react';
import '../../App.css';
import Carousel from 'react-bootstrap/Carousel'
import './Ingrasaminte.css'

import 'bootstrap'
import { Container } from 'react-bootstrap';


function Carduri() {
  document.documentElement.style.setProperty('--color', `white`);
 


  return (
    <div>
       <img src='fuel_pelicula.jpg' className='bg'></img>
       <Container>
        <br />
        <br />
        <p class="fs-1">CARDURI PREPAID CARBURANT</p>

      </Container>
      <br />
      <p className="normal-text">Cardul prepay de carburant obținut în parteneriat cu INTERGLOBAL MB OIL  vă oferă un discount suplimentar față de prețul de pompă , peste discountul în vigoare de 0.50 lei oferit în acest moment  din partea statului .
</p>
      <br />
      <p className="normal-text">
        <img src="images/carduri/card3.png" class="rounded mx-auto d-block" alt="..." width={500} height={300} />
        Cardul de combustibil este mai mult decât un card de combustibil, este un instrument de administrare eficientă a consumului de combustibil pentru toată flota sau mașina personală (facilitează controlul asupra flotei și cheltuielilor).</p>
      <br />
     
      <p className="normal-text">Clienții pot primi notificări pe email cu privire la tranzacțiile efectuate (data, ora, cod card, stația unde a fost făcută alimentarea, număr inmatriculare, litri alimentați, tip combustibil, preț unitar, valoarea tranzacției și soldul disponibil la fiecare din furnizori ).
      </p>
      <br />

     
    </div>
  )
}

export default Carduri;