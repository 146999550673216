import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route,Link } from 'react-router-dom';

import { BrowserRouter ,HashRouter} from 'react-router-dom';


import Carduri from './components/pages/Carduri';
import Products from './components/pages/Products';
import Agricultura from './components/pages/Agricultura';
import MotorinaEnGross from './components/pages/MotorinaEnGross';
import MotorinaDomiciliu from './components/pages/MotorinaDomiciliu';
import Motorina from './components/pages/Motorina';
import Despre from './components/pages/Despre';
import Contact from './components/pages/Contact';
import Recenzii from './components/pages/Recenzii';
import Ingrasaminte from './components/pages/Ingrasaminte';
import Cereale from './components/pages/Cereale';
import NavbarB from './components/NavbarB';
import Home from './components/pages/Home';


function App() {
  return (
    <div className="App">
      
      <NavbarB/>
      <BrowserRouter>
         <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/carduri' element={<Carduri/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/agricultura' element={<Agricultura/>} />
          <Route path ='/motorina' element={<Motorina/>} />
          <Route path='/enGross' element={<MotorinaEnGross/>} />
          <Route path='/domiciliu' element={<MotorinaDomiciliu/>} />
          <Route path ='/despre' element = {<Despre/>}/>
          <Route path ='/contact' element = {<Contact/>}/>
          <Route path ='/recenzii' element = {<Recenzii/>}/>
          <Route path ='/ingrasaminte' element = {<Ingrasaminte/>}/>
          <Route path ='/cereale' element = {<Cereale/>}/>

         </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;