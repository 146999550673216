import { useEffect, useState } from "react"
import { addDoc, collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase'
import { get } from "firebase/database"
import DOMPurify from 'dompurify';
import filter from '../../WordFilter'

import './Comments.css'
import Comment from "./Comment"
import CommentForm from "./CommentForm"

export default function Comments() {


  const [backendComments, setBackendComments] = useState([])
  const commentsCollectionRef = collection(db, "comments")
  const [activeComment, setActiveComment] = useState(null)

  function compare( a, b ) {
    if ( a.time > b.time ){
      return 1;
    }
    if ( a.time < b.time ){
      return -1;
    }
    return 0;
  }
  useEffect(() => {

    const getComments = async () => {

      const data = await getDocs(commentsCollectionRef)
      

      setBackendComments(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      console.log("inside promise backend is " + backendComments)
      

    }
    

    getComments();
    console.log("Comment before backend array")
    console.log(backendComments);

  }, [])


  const storeComment = async(comment) => {

    await addDoc(commentsCollectionRef,{name : comment.name, message : comment.message, time : comment.createdAt})
  }
  const createComment = async (text, name) => {

const d = new Date();
console.log(d.getFullYear());

var day = d.getDate();
if(day < '10')
day = '0' + day;
var month = d.getMonth()+1;
if(month < '10')
month = '0' + month;
if(month === '13')
month = '01'
var hours = d.getHours();
if(hours < "10")
hours = '0' + hours;
var minutes = d.getMinutes();
if(minutes< '10')
minutes = '0' + minutes;

text =  DOMPurify.sanitize(text);
text = filter.clean(text)
name = DOMPurify.sanitize(name);
name = filter.clean(name)
if(text.length === 0 || name.length === 0)
return null;
var time = day + '/' + month + '/' + d.getFullYear() + ' ' + hours + ':' + minutes

    return { 
      id: Math.random().toString(36).substr(2, 9),
      message: text,
      name: name,
      createdAt: time,
    };
  };

  const addComment = (text, name) => {
    createComment(text, name).then((comment) => {

      if( comment.message.localeCompare("")){

      setBackendComments([comment, ...backendComments]);
      setActiveComment(null);
      storeComment(comment)
    }
    });
  };

  return (<div className="comments">
    <h3 className="comments-title">
      Comments
    </h3>
    <div className="comment-form-title">Lasa-ne un comentariu!</div>
    <CommentForm submitLabel= "Posteaza" handleSubmit={addComment}></CommentForm>
    <div className="comments-container">

      {backendComments.sort(compare).map((comment) => (

        <Comment

          key={comment.id}
          comment={comment}
          
        />
      ))}
    </div>
  </div>);
}