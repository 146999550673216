import React from 'react';
import '../App.css';
import { Button } from 'react-bootstrap';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
    <video src='/videos/earth.mp4' autoPlay loop muted />
      <h1>ADVENTURE AWAITS</h1>
      <br/>
      <br/>
      <p>
      Because we know how much the quality means to you.     </p>
      <div className='hero-btns'>
    
      </div>
    </div>
  );
}

export default HeroSection;
