import { useState } from "react";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
  initialName = ""
}) => {
  const [text, setText] = useState(initialText);
  const [name, setName] = useState(initialName);

  const isTextareaDisabled = text.length === 0 ;

  const isNameEmpty = name.length === 0;

  const buttonDisabled = isTextareaDisabled || isNameEmpty;


  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text, name);
    setText("");
  };



  return (
    <form onSubmit={onSubmit}>

      <textarea
        className="comment-form-name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder = "Nume"
      />
      <textarea
        className="comment-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder = "Scrie-ne un mesaj!"
      />

      <button className="comment-form-button" disabled={buttonDisabled}>
        {submitLabel}
      </button>
      {hasCancelButton && (
        <button
          type="button"
          className="comment-form-button comment-form-cancel-button"
          onClick={handleCancel}
        >
          Cancel
        </button>
      )}
    </form>
  );
};

export default CommentForm;  