import CommentForm from "./CommentForm";

const Comment = ({
  comment
}) => {
 
 
  const createdAt = new Date(comment.time).toLocaleDateString();
 

  return (
    <div key={comment.id} className="comment">
      <div className="comment-image-container">
        <img src="/images/user-icon.png" />
      </div>
      <div className="comment-right-part">
        <div className="comment-content">
          <div className="comment-author">{comment.name}</div>
          <div>{comment.time}</div>
        </div>
        { <div className="comment-text">{comment.message}</div>}
      
    </div>
    </div>
  );
};

export default Comment;